
import {
  Component, Vue, Prop, Emit, Ref,
} from "vue-property-decorator";
import moment from "moment";
import { State, Action } from "vuex-class";
import { Team } from "@/model/team.model";
import { Nomination } from "@/model/nomination.model";
import {
  dateTimeMomentFormat, dismissAction, logAndExtractMessage,
  NOMINATION_TYPE_MANAGER,
  NOMINATION_TYPES_BY_VALUE, paginateTable,
} from "@/utils/utils";
import { getAcceptedMemberNominationsModuleNamespace, getNominationModuleNamespace } from "@/store/store.utils";
import { CurrentPage, Pagination } from "@/model/types";

@Component({
  components: {
    NominationStateComponent: () => import("../custom-components/NominationStateComponent.vue"),
    ConfirmationDialog: () => import("../dialogs/ConfirmationDialog.vue"),
  },
})
export default class NominateReplacementManagerDialog extends Vue {
  moment = moment;

  NOMINATION_TYPES_BY_VALUE = NOMINATION_TYPES_BY_VALUE;

  dateTimeMomentFormat = dateTimeMomentFormat;

  footerProps = { "items-per-page-options": [25, 50, 100] };

  open = false;

  localStorageTableSizeKey = "team.accepted.member.nominations.table.size";

  loading = false;

  tableInited = false;

  headers = [
    {
      text: "First Name",
      value: "user.firstName",
      sortable: false,
    },
    {
      text: "Last Name",
      value: "user.lastName",
      sortable: false,
    },
    {
      text: "Email",
      value: "user.email",
      sortable: false,
    },
    {
      text: "Nomination Type",
      value: "nominationType",
      sortable: false,
    },
    { text: "Approval State", value: "nominationApprovalState", sortable: false },
    { text: "Invitation Expires At", value: "expiresAt", sortable: false },
    { text: "Created at", value: "createdAt", sortable: false },
    { text: "Updated at", value: "updatedAt", sortable: false },
    { text: "Actions", value: "actions", sortable: false },
  ];

  @Prop()
  pTeam!: Team;

  @Ref()
  confirmNominationDialog!: any;

  @State("acceptedMemberNominations", getAcceptedMemberNominationsModuleNamespace())
  acceptedMemberNominations!: Nomination[]

  @State("acceptedMemberNominationsLoading", getAcceptedMemberNominationsModuleNamespace())
  acceptedMemberNominationsLoading!: Nomination[]

  @State("totalCount", getAcceptedMemberNominationsModuleNamespace())
  totalCount!: Nomination[]

  @Action("loadPage", getAcceptedMemberNominationsModuleNamespace())
  loadAcceptedMemberNominations!: (currentPage: CurrentPage) => Promise<void>

  @Action("reloadPage", getNominationModuleNamespace())
  reloadNominations!: () => Promise<void>

  @Emit("onUpdate")
  emitOnUpdate() {
    return true;
  }

  paginate(data: Pagination) {
    data.itemsPerPage = paginateTable(data, this.localStorageTableSizeKey, this.tableInited);

    const currentPage = {
      index: data.page - 1,
      size: data.itemsPerPage,
    };

    this.loadPage(currentPage);
    this.tableInited = true;
  }

  async loadPage(currentPage: CurrentPage) {
    const sentCurrentPage = {
      ...currentPage,
      teamId: this.pTeam.id,
    };

    try {
      await this.loadAcceptedMemberNominations(sentCurrentPage);
    } catch (e) {
      Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
    }
  }

  openConfirmationNominationDialog(nomination: Nomination) {
    const text = `You are nominating ${nomination.user.fullName} at email ${nomination.user.email} to become
     the new manager of the team.  Once they accept, the team will restart automatically.
     You may cancel the nomination at any time.
     It will expire in 5 days or should the member decline.

     Do you wish to continue?`;

    this.confirmNominationDialog.openDialog({
      header: "Confirm Nomination",
      text,
      submitText: "Confirm",
      submitColor: "green",
      onSubmit: async () => {
        await this.confirmNomination(nomination);
      },
    });
  }

  async confirmNomination(nomination: Nomination) {
    this.loading = true;

    try {
      await this.$store.dispatch("nominationModule/saveNomination", {
        nomination: {
          email: nomination.user.email,
          nominationType: NOMINATION_TYPE_MANAGER.value,
        },
        teamId: nomination.teamId,
      });
      this.reloadNominations();
      this.emitOnUpdate();
      this.closeDialog();
    } catch (e) {
      Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
    }

    this.loading = false;
  }

  closeDialog() {
    this.open = false;
  }

  openDialog() {
    this.open = true;
  }
}
